<template>
    <b-row>
        <b-col cols="8">
            <b-card>
                <b-overlay
                    variant="white"
                    :show="showLoading"
                    spinner-variant="primary"
                    blur="0"
                    opacity=".75"
                    rounded="sm"
                >
                    <b-row>
                        <h6 class="section-label mx-1 mb-2">
                            Gerencia
                        </h6>
                        <b-col v-if="responsibles.length!==0" cols="12">
                            <b-form-group label="Responsable del Mòdul" label-for="value">
                                <v-select
                                    v-model="responsibles.find(r => r.module === 'gerency').users"
                                    :options="users"
                                    :clearable="false"
                                    label="name"
                                    :multiple="true"
                                    :reduce="(option) => option.id"
                                >
                                    <template #no-options="{ }">
                                        No s'han pogut carregar els usuaris
                                    </template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <h6 class="section-label mx-1 mt-1 mb-2">
                            Responsable Mòdul Assegurances
                        </h6>
                        <b-col v-if="responsibles.length!==0" cols="12">
                            <b-form-group label="Responsable del Mòdul" label-for="value">
                                <v-select
                                    v-model="responsibles.find(r => r.module === 'insurance').users"
                                    :options="users"
                                    :clearable="false"
                                    label="name"
                                    :multiple="true"
                                    :reduce="(option) => option.id"
                                >
                                    <template #no-options="{ }">
                                        No s'han pogut carregar els usuaris
                                    </template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <h6 class="section-label mx-1 mt-1 mb-2">
                            Responsable mòdul finances
                        </h6>
                        <b-col v-if="responsibles.length!==0" cols="12">
                            <b-form-group label="Responsable del Mòdul" label-for="value">
                                <v-select
                                    v-model="responsibles.find(r => r.module === 'finance').users"
                                    :options="users"
                                    :clearable="false"
                                    label="name"
                                    :multiple="true"
                                    :reduce="(option) => option.id"
                                >
                                    <template #no-options="{ }">
                                        No s'han pogut carregar els usuaris
                                    </template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <h6 class="section-label mx-1 mt-1 mb-2">
                            Responsable mòdul mercantil
                        </h6>
                        <b-col v-if="responsibles.length!==0" cols="12">
                            <b-form-group label="Responsable del Mòdul" label-for="value">
                                <v-select
                                    v-model="responsibles.find(r => r.module === 'trading').users"
                                    :options="users"
                                    :clearable="false"
                                    label="name"
                                    :multiple="true"
                                    :reduce="(option) => option.id"
                                >
                                    <template #no-options="{ }">
                                        No s'han pogut carregar els usuaris
                                    </template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <h6 class="section-label mx-1 mb-2 mt-1">
                            Responsables Mòdul Contabilitat y Requeriments
                        </h6>
                        <b-col v-if="responsibles.length!==0" cols="12">
                            <b-form-group label="Responsable del Mòdul" label-for="value">
                                <v-select
                                    v-model="responsibles.find(r => r.module === 'accounting').users"
                                    :options="users"
                                    :clearable="false"
                                    label="name"
                                    :multiple="true"
                                    :reduce="(option) => option.id"
                                >
                                    <template #no-options="{ }">
                                        No s'han pogut carregar els usuaris
                                    </template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col v-if="responsibles.length!==0" cols="12">
                            <b-form-group label="Responsable de fase preparació" label-for="value">
                                <v-select
                                    v-model="responsibles.find(r => r.phase === '1').users"
                                    :options="users"
                                    :clearable="false"
                                    label="name"
                                    :multiple="true"
                                    :reduce="(option) => option.id"
                                >
                                    <template #no-options="{ }">
                                        No s'han pogut carregar els usuaris
                                    </template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col v-if="responsibles.length!==0" cols="12">
                            <b-form-group label="Responsable de fase al·legació" label-for="value">
                                <v-select
                                    v-model="responsibles.find(r => r.phase === '2').users"
                                    :clearable="false"
                                    :options="users"
                                    label="name"
                                    :multiple="true"
                                    :reduce="(option) => option.id"
                                >
                                    <template #no-options="{ }">
                                        No s'han pogut carregar els usuaris
                                    </template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col v-if="responsibles.length!==0" cols="12">
                            <b-form-group label="Responsable de fase canvis al·legació" label-for="value">
                                <v-select
                                    v-model="responsibles.find(r => r.phase === '8').users"
                                    :clearable="false"
                                    :options="users"
                                    label="name"
                                    :multiple="true"
                                    :reduce="(option) => option.id"
                                >
                                    <template #no-options="{ }">
                                        No s'han pogut carregar els usuaris
                                    </template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col v-if="responsibles.length!==0" cols="12">
                            <b-form-group label="Responsable de fase revisió" label-for="value">
                                <v-select
                                    v-model="responsibles.find(r => r.phase === '3').users"
                                    :clearable="false"
                                    :options="users"
                                    label="name"
                                    :multiple="true"
                                    :reduce="(option) => option.id"
                                >
                                    <template #no-options="{ }">
                                        No s'han pogut carregar els usuaris
                                    </template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col v-if="responsibles.length!==0" cols="12">
                            <b-form-group label="Responsable de fase finalització de al·legació/avís de facturació" label-for="value">
                                <v-select
                                    v-model="responsibles.find(r => r.phase === '9').users"
                                    :clearable="false"
                                    :options="users"
                                    label="name"
                                    :multiple="true"
                                    :reduce="(option) => option.id"
                                >
                                    <template #no-options="{ }">
                                        No s'han pogut carregar els usuaris
                                    </template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col v-if="responsibles.length!==0" cols="12">
                            <b-form-group label="Responsable de fase enviament telemàtic" label-for="value">
                                <v-select
                                    v-model="responsibles.find(r => r.phase === '4').users"
                                    :clearable="false"
                                    :options="users"
                                    label="name"
                                    :multiple="true"
                                    :reduce="(option) => option.id"
                                >
                                    <template #no-options="{ }">
                                        No s'han pogut carregar els usuaris
                                    </template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col v-if="responsibles.length!==0" cols="12">
                            <b-form-group label="Responsable de fase de confirmació de rebuda" label-for="value">
                                <v-select
                                    v-model="responsibles.find(r => r.phase === '5').users"
                                    :clearable="false"
                                    :options="users"
                                    label="name"
                                    :multiple="true"
                                    :reduce="(option) => option.id"
                                >
                                    <template #no-options="{ }">
                                        No s'han pogut carregar els usuaris
                                    </template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <h6 class="section-label mx-1 mt-1 mb-2">
                            Responsable Mòdul Laboral
                        </h6>
                        <b-col v-if="responsibles.length!==0" cols="12">
                            <b-form-group label="Responsable del Mòdul" label-for="value">
                                <v-select
                                    v-model="responsibles.find(r => r.module === 'labor').users"
                                    :options="users"
                                    :clearable="false"
                                    label="name"
                                    :multiple="true"
                                    :reduce="(option) => option.id"
                                >
                                    <template #no-options="{ }">
                                        No s'han pogut carregar els usuaris
                                    </template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <div>
                        <b-row align-h="start">
                            <b-col cols="5">
                                <b-button variant="primary" @click="updateResponsibles">
                                    Desar Canvis
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>
                </b-overlay>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {BButton, BCard, BCol, BFormGroup, BOverlay, BRow} from "bootstrap-vue"
import {departments} from "@/constants/departments"
import vSelect from "vue-select"
import ToastificationContent from "@core/components/toastification/ToastificationContent"

export default {
    name: "FormView",
    components: {
        BCol,
        BRow,
        BButton,
        BCard,
        BOverlay,
        BFormGroup,

        vSelect,
    },
    data() {
        return {
            name: null,
            email: null,
            password: null,
            department: [],
            edit: false,
            showLoading: false,
            password_confirmation: null,

            users: [],
            responsibles: [],
            departments,
        }
    },
    created() {
        this.getUsers()
        this.getResponsibles()
    },
    methods: {
        getUsers() {
            this.showLoading = true

            this.$store.dispatch('user/getAllUsers', {
                id: this.$route.params.id,
            })
                .then(response => {
                    this.users = response
                    this.showLoading = false
                })
                .catch(error => {
                    this.showLoading = false
                })
        },
        getResponsibles() {
            this.$store.dispatch('responsible/getAll', {
                relations: ['users'],
            })
                .then(response => {
                    response.forEach(function (item) {
                        item.users = item.users.map(function (user) {
                            return user['id']
                        })
                    })
                    this.responsibles = response
                    console.log(this.responsibles)
                })
                .catch(error => {
                    this.showLoading = false
                })
        },
        updateResponsibles() {
            this.showLoading = true

            this.$store.dispatch('responsible/update', this.responsibles)
                .then(response => {
                    this.showLoading = false
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Responsables actualitzats correctament!`,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                })
                .catch(error => {
                    this.showLoading = false
                })
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>