export const departments = [
	{id: 1, name: 'Laboral'},
	{id: 2, name: 'Financer'},
	{id: 3, name: 'Assegurances'},
	{id: 4, name: 'Mercantil i herències'},
	{id: 5, name: 'Comptabilitat i fiscalitat'},
]

export const ID_DEPARTMENT_LABOR = 1
export const ID_DEPARTMENT_FINANCE = 2
export const ID_DEPARTMENT_INSURANCE = 3
export const ID_DEPARTMENT_TRADING = 4
export const ID_DEPARTMENT_ACCOUNTING = 5